export const WarningIcon = ({ small = true }) => (
  <svg
    className={` w-6 h-6 ${small ? 'md:w-4 md:h-4' : ''}`}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 12C20 7.6129 16.3871 4 12 4C7.58065 4 4 7.6129 4 12C4 16.4194 7.58065 20 12 20C16.3871 20 20 16.4194 20 12ZM12 13.6129C12.8065 13.6129 13.4839 14.2903 13.4839 15.0968C13.4839 15.9355 12.8065 16.5806 12 16.5806C11.1613 16.5806 10.5161 15.9355 10.5161 15.0968C10.5161 14.2903 11.1613 13.6129 12 13.6129ZM10.5806 8.29032C10.5484 8.06452 10.7419 7.87097 10.9677 7.87097H13C13.2258 7.87097 13.4194 8.06452 13.3871 8.29032L13.1613 12.6774C13.129 12.9032 12.9677 13.0323 12.7742 13.0323H11.1935C11 13.0323 10.8387 12.9032 10.8065 12.6774L10.5806 8.29032Z"
      fill="#E22C43"
    />
  </svg>
);
