import { CheckMarkIcon } from '@icons/CheckMarkIcon';
import { PlusIcon } from '@icons/PlusIcon';
import { WarningIcon } from '@icons/WarningIcon';
import { ReactNode, useRef, useState } from 'react';
import type { FormFileUploader } from 'server/repository/hygraph/generated/graphqlTypes';
import { Text as Paragraph } from '../../../../Typography';
import {
  FileButton,
  FileButtonWrapper,
  FileInput,
  FileStatusButton,
  FileStatusTextIcon,
  FileUploadContainer,
  FileUploadText,
  FileUploadWrapper,
  UploadErrorMessage,
} from '../../../styles';

export const FileUploader = ({
  field,
  formikProps,
  count,
}: {
  field: FormFileUploader;
  formikProps: any;
  count: number;
}): ReactNode => {
  const fieldName = field.fieldName || 'file';
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [sizeError, setSizeError] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);

  const requiredError = field.required && !formikProps.values[fieldName] && count > 1;

  const uploadFile = (e: any) => {
    e.preventDefault();
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  const processFile = (file: any): void => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileString = e.target?.result;
      formikProps.setFieldValue(fieldName, fileString);
    };
    reader.readAsDataURL(file);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    const file = e.target?.files[0];
    if (file) {
      const maxAllowedSize = 10 * 1024 * 1024;
      if (file.size <= maxAllowedSize) {
        processFile(file);
        setSizeError(false);
        setUploadSuccess(true);
      } else {
        setSizeError(true);
        setUploadSuccess(false);
      }
    } else {
      setUploadSuccess(false);
    }
  };

  const deleteFile = (e: any) => {
    e.preventDefault();
    formikProps.setFieldValue(fieldName, '');
    setSizeError(false);
    setUploadSuccess(false);
  };

  return (
    <>
      <FileUploadContainer>
        {!uploadSuccess && (
          <FileUploadWrapper>
            <FileButtonWrapper>
              <FileInput
                type="file"
                id={fieldName}
                name={fieldName}
                accept="image/*"
                ref={fileInputRef}
                onChange={handleChange}
              />
              <FileButton id="fileSelect" onClick={uploadFile}>
                <PlusIcon />
                {field.uploadButton || 'Upload image'}
              </FileButton>
            </FileButtonWrapper>
            {field.description && <FileUploadText>{field.description}</FileUploadText>}
          </FileUploadWrapper>
        )}

        {uploadSuccess && (
          <FileUploadWrapper>
            <FileStatusTextIcon>
              <CheckMarkIcon />
              {field.successMessage || 'Image uploaded successfully'}
            </FileStatusTextIcon>
            <FileButtonWrapper>
              <FileStatusButton onClick={deleteFile}>
                {field.deleteButton || 'Delete'}
              </FileStatusButton>
            </FileButtonWrapper>
          </FileUploadWrapper>
        )}
      </FileUploadContainer>

      {(sizeError || requiredError) && (
        <UploadErrorMessage>
          <WarningIcon small={false} />
          <Paragraph tag="span" type="md">
            {sizeError ? field.sizeErrorMessage : field.requiredErrorMessage}
          </Paragraph>
        </UploadErrorMessage>
      )}
    </>
  );
};
