import { COLORS } from 'styles/constants';

export const Success = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" transform="rotate(-90 30 30)" fill={COLORS.primary} />
    <path
      d="M36.2845 24.1277L27.0766 33.0809L23.7155 29.7787C23.5405 29.6426 23.2604 29.6426 23.1204 29.7787L22.105 30.766C21.965 30.9021 21.965 31.1745 22.105 31.3447L26.7965 35.8723C26.9716 36.0426 27.2166 36.0426 27.3917 35.8723L37.895 25.6596C38.035 25.5234 38.035 25.2511 37.895 25.0809L36.8797 24.1277C36.7396 23.9574 36.4595 23.9574 36.2845 24.1277Z"
      fill="white"
    />
  </svg>
);
