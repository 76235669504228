import styled from 'styled-components';
import { COLORS } from 'styles/constants';
import tw from 'tailwind-styled-components';
import { Text as Paragraph, Title } from '../Typography';

export const Container = tw.div`
  py-4
  sm:w-full
  md:w-1/2
  form-fields
`;
Container.displayName = 'Container';

export const AccordionContainer = styled.div`
  .form-fields {
    width: 100%;
  }
`;

export const Input = styled.input<{ $hasError?: boolean }>`
  border: 2px solid ${COLORS.darkGray};
  border-radius: 16px;
  display: block;
  padding: 10px 20px;
  width: 100%;
  transition: border-color 0.2s;

  &:focus {
    border-color: ${COLORS.secondary};
    --tw-ring-shadow: ${COLORS.secondary};
  }

  ${({ $hasError }) =>
    $hasError &&
    `
      border-color: ${COLORS.error};
    `}
`;
Input.displayName = 'Input';

export const Textarea = styled.textarea<{ $hasError?: boolean }>`
  border: 2px solid ${COLORS.primary};
  border-radius: 16px;
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px 20px;
  width: 100%;
  overflow: hidden;

  &:focus {
    border-color: var(--gray-500);
    box-shadow: none;
  }

  ${({ $hasError }) =>
    $hasError &&
    `
      border-color: ${COLORS.error};
    `}
`;
Textarea.displayName = 'Textarea';

export const Label = styled.label`
  color: var(--color-text-darkgray, #5a5e5a);
  font-size: 14px;
  display: block;
  text-transform: uppercase;
  letter-spacing: 2.1px;
`;
Label.displayName = 'Label';

export const ErrorMessage = styled.p`
  color: ${COLORS.error};
  margin: 0;
`;
ErrorMessage.displayName = 'ErrorMessage';

export const SuccessMessage = styled(Title)`
  color: #5a5e5a;
  margin: 0;
  margin-top: 40px;
`;
SuccessMessage.displayName = 'SuccessMessage';

export const SuccessDescription = styled(Paragraph)`
  color: #5a5e5a;
  margin: 20px 0;
`;
SuccessDescription.displayName = 'SuccessDescription';

export const CheckboxInput = styled.input<{ $hasError?: boolean }>`
  margin-right: 10px;
  margin-bottom: 6px;
  border: 2px solid ${COLORS.secondary};
  border-radius: 2px;
  vertical-align: top;
  outline: transparent;
  width: 24px;
  height: 24px;

  &:checked:focus,
  &:checked,
  &:checked:hover {
    background-color: ${COLORS.primary};
  }
  &:focus {
    --tw-ring-color: var(--green-500);
    --tw-ring-offset-width: 0;
  }
  &:disabled {
    background-color: transparent;
    border-color: var(--lightgray);
  }
  &:checked:disabled {
    border-color: var(--lightgray);
    background-color: var(--lightgray);
  }

  ${({ $hasError }) =>
    $hasError &&
    `
      border-color: ${COLORS.error};
    `}
`;
CheckboxInput.displayName = 'CheckboxInput';

export const SubmitInput = styled.input`
  padding: 1.25rem 2rem;
  border: 2px solid ${COLORS.primary};
  border-radius: 1.5rem;
  background-color: transparent;
  text-transform: uppercase;
`;
SubmitInput.displayName = 'SubmitInput';

export const StyledSubmitButton = tw.button`
  w-max
`;
StyledSubmitButton.displayName = 'StyledSubmitButton';

export const FormElements = tw.div`
  sm:w-full
  inline-block
  align-top
  md:mr-6
  sm:mr-0
`;
FormElements.displayName = 'FormElements';

export const FormLabel = tw(Paragraph)`
  inline-block
  w-full
  mb-[10px]
  form-text
`;
FormLabel.displayName = 'FormLabel';

export const OptionsBox = tw.ul`
  rounded-2xl
  md:w-full
  border
  border-grayBorder
  p-0
  bg-light-brown
  max-h-countrySelectorHeight
  overflow-auto
  mt-2.5
`;
OptionsBox.displayName = 'OptionsBox';

export const OptionWrapper = styled.li`
  min-height: 60px;
  &:first-child {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  &.selected {
    background-color: ${COLORS.primary};
    border-color: #5a5e5a;
    label {
      color: ${COLORS.white};
    }
  }
`;
OptionWrapper.displayName = 'OptionWrapper';

export const OptionLabel = tw.label`
  block
  py-18
  px-5
  font-neutral
  tracking-wider
  text-sm
  text-darkgray
`;
OptionLabel.displayName = 'OptionLabel';

export const MainLabel = tw.label`
  inline-block
  relative
  w-full
`;
MainLabel.displayName = 'MainLabel';

export const LeftIcon = tw.span`
  absolute
  left-5
  bottom-1.5
`;
LeftIcon.displayName = 'LeftIcon';

export const SuccessWrapper = styled.div<{ $scroll?: boolean }>`
  text-align: center;
  margin: 70px 20px;
  svg {
    margin: auto;
  }
  @media (max-width: 480px) {
    margin: 60px 20px 75px;
  }
  ${({ $scroll }) => $scroll && 'scroll-margin-top: 100px;'}
`;
SuccessWrapper.displayName = 'SuccessWrapper';

export const ButtonFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -33px;
`;

ButtonFlexContainer.displayName = 'ButtonFlexContainer';

export const CheckboxCollectionDiv = tw.div`
  pt-5
`;
export const CollectionCheckbox = tw.label`
  pr-5
`;
export const StyledCollectionCheckbox = styled.label`
  padding-right: 1.25rem;

  input:disabled + span {
    color: var(--lightgray);
  }
`;
export const StyledLabel = styled.label`
  input:disabled + p {
    color: var(--lightgray);
  }
`;
export const RadioLabel = styled.label`
  input:disabled + span {
    color: var(--lightgray);
  }
`;
export const RadioButton = tw.div`
  pr-5
  inline-block
  pt-5
`;

export const MultiStepOptionLabel = tw.label<{ $isActive?: boolean }>`
  flex
  items-center
  px-[20px]
  w-[100%]
  h-[62px]
  bg-white
  border-2
  rounded-[6px]
  border-light-gray
  mb-[16px]
  last:mb-0
  cursor-pointer
  transition-colors

    ${({ $isActive }) => $isActive && 'border-light-brown bg-light-brown'}
`;
export const RadioInput = styled.input`
  border-color: ${COLORS.primary};
  width: 20px;
  height: 20px;
  position: relative;
  border-width: 1px;

  &:focus:checked,
  &:hover:checked,
  &:focus,
  &:checked {
    --tw-ring-color: transparent;
    --tw-ring-offset-width: 0;
    border-color: ${COLORS.primary};
    background-color: transparent;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: transparent;
    transition: background-color 0.2s;
  }

  &:checked::before {
    background-color: ${COLORS.primary};
  }

  &:disabled {
    border-color: var(--lightgray);
  }

  &:checked:disabled {
    border-color: var(--lightgray);
    background-color: var(--lightgray);
  }
`;

export const CheckboxLabel = tw.div`
  inline-block
  w-11/12
  m-0
  overflow-hidden
  md:pr-0
`;
export const CheckboxLabelHtml = tw.div`
  flex
`;
export const WarningIconSpan = tw.span`
  absolute
  right-6
  bottom-5
  w-6
  h-6
  md:right-5
  md:w-5
  md:h-5

  ${(p: { $isDate?: boolean }) =>
    p.$isDate ? 'mr-7 mb-0.5 md:mr-6 md:pt-1 md:right-6 md:bottom-6' : ''}
 `;

export const CheckIconSpan = tw.span`
  absolute
  right-6
  bottom-6
  w-4
  h-4

  ${(p: { $isDate?: boolean }) => (p.$isDate ? 'mr-7 mb-0.5' : '')}
 `;

export const FileUploadContainer = tw.div`
  sm:w-full
  md:w-1/2
  form-fields
  border-green-curve
  border-1
  border-dashed
  rounded-2xl
  py-10
  px-6
  flex
  flex-col
  justify-center
  hover:bg-gray
`;

export const FileUploadWrapper = tw.div`
  flex
  flex-col
  gap-y-8
`;

export const FileButtonWrapper = tw.div`
  flex
  justify-center
`;

export const FileInput = tw.input`
  hidden
`;

export const FileButton = tw.button`
  flex
  gap-x-2.5
  border
  border-black
  rounded-3xl
  py-2
  px-5
  text-sm
  uppercase
  tracking-xl
  leading-6
`;

export const FileUploadText = tw.div`
  text-center
  text-sm
  tracking-wide
  leading-6
`;

export const FileStatusText = tw.div`
  text-center
  tracking-wide
  leading-6
`;

export const FileStatusTextIcon = tw.div`
  tracking-wide
  leading-6
  flex
  justify-center
  gap-x-4
`;

export const FileStatusButton = tw.button`
  border
  border-black
  rounded-3xl
  py-2
  px-5
  text-sm
  uppercase
  tracking-xl
  leading-6

`;

export const UploadErrorMessage = tw.div`
  sm:w-full
  md:w-1/2
  mt-5
  flex
  items-center
  gap-x-5
  text-error
`;

export const FieldContainer = tw.div`
  mb-[24px]
`;

export const Select = tw.select`
  rounded-2xl
  transition-all
  pr-5
  pl-4
  py-2.5
  appearance-none
  bg-none
  border-grayBorder
  border-[2px]
  w-full
  focus:border-light-brown
  focus:ring-offset-0
  focus:ring-0
`;
